import React from "react";
import {Header} from "../Header/Header";
import {MapFullPage} from "../MapFullPage/MapFullPage";


export const MapView = () => {


    return (
        <>
            <Header/>
            <MapFullPage/>
        </>
    )
}